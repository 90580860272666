// Aleatorio.js
function Aleatorio(de, ate, quant) {
  de = parseInt(de);
  ate = parseInt(ate);
  quant = parseInt(quant);

  // Verifica se o intervalo e a quantidade são válidos
  if (de >= ate) {
    throw new Error("O valor 'de' deve ser menor que 'ate'.");
  }
  if (quant > ate - de + 1) {
    throw new Error("Quantidade de números a sortear é maior que o intervalo disponível.");
  }

  let results = new Set();

  while (results.size < quant) {
    const num = Math.floor(Math.random() * (ate - de + 1)) + de;
    results.add(num);
  }

  return Array.from(results);
}

export default Aleatorio;
